.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width:100%;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #6c303d;
  color: white;
}

.logo-and-title {
  display: flex;
  align-items: center;
}

.logo {
  width: 200px;
  height: auto;
  margin-right: 20px;
}

.title {
  text-align: center;
  margin-top: 25px;
  margin-bottom: 0px;
  color: white;
  font-family: Roboto, Helvetica, Arial, Lucida, sans-serif;
}

.logout-button {
  background-color: #a0f0ed;
  color: black;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.logout-button:hover {
  background-color: #c82333;
}

.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 20px;
  overflow: hidden;
  width: 80%;
}

.content-container {
  display: flex;
  flex: 1;
  overflow: hidden;
}

.left-container {
  flex: 1;
  margin-right: 20px;
  overflow-y: auto; /* Allow scrolling within this container */
  min-width: 0; /* Allow flex item to shrink below its minimum content size */
}

.right-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto; /* Allow scrolling within this container */
  min-width: 0; /* Allow flex item to shrink below its minimum content size */
}

.user-info {
  padding: 20px;
  background-color: #f0f0f0;
  border-radius: 8px;
  overflow-y: auto; /* Allow scrolling if content is too long */
  flex: 1;
}
 

.button-container {
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
}

.table-container {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  border-radius: 8px;
  overflow: hidden;
  max-height: calc(100vh - 200px); /* Adjust this value as needed */
  overflow-y: auto;
}


.table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

.table-header {
  background-color: #f0f0f0;
}

.table-header-cell {
  padding: 12px 15px;
  font-weight: bold;
  text-align: left;
  border-bottom: 2px solid #ddd;
  cursor: pointer;
}

.table-row {
  cursor: pointer;

}

.table-cell {
  padding: 12px 15px;
  border-bottom: 1px solid #ddd;
}

/* .selected-row {
  background-color: #e0e0e0 !important;
} */

/* .selected-row:hover {
  background-color: #995050 !important;
} */

.home-directory-details {
  background-color: #f9f9f9;
  padding: 10px;
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.directory-mapping {
  margin-bottom: 15px;
  border-bottom: 1px solid #eee;
  padding-bottom: 15px;
}

.path-container {
  display: flex;
  align-items: center;
  background-color: #f0f0f0;
  padding: 5px 10px;
  border-radius: 4px;
  margin-top: 5px;
}

.folder-icon {
  color: #4a4a4a;
  margin-right: 10px;
  font-size: 1.2em;
}

.path-text {
  color: #4a4a4a;
  font-weight: bold;
  word-break: break-all;
}
button, .amplify-button {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: black;
  background-color: #a0f0ed;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover, .amplify-button:hover {
  opacity: 0.9;
  background-color: #c82333;
}